import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import InViewMonitor from 'react-inview-monitor'
import CreateHeroHompageSr from '../components/HeroHomepageSr'
import VerticalProjectsSr from '../components/VerticalProjectsComponentSr'
import HomepageWhoWeAreSr from '../components/HomepageWhoWeAreComponentSr'
import NavbarSr from '../components/NavbarSr'
import FooterSr from '../components/FooterSr'

import Favicon from '../favicon-32x32.png'
import ogMetaImgSr from '../img/an_og_index_sr.jpg'

const HomepagePage = ({ data }) => {
  const { allWpProjectPostType: projects } = data
  const { allWpPage: thepage } = data

  const featuredProjectsElements = data.allWpProjectPostType.edges

  const leIndex = 0

  return (
    <div>
      {/* <NavbarSr /> */}
      <div key="hp">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Početna | Interior Design Studio</title>
          <link rel="canonical" href="https://ananenadovic.com/sr/" />
          <meta
            name="description"
            content="Studio se nalazi u Beogradu. Osnovan je 2013.godine sa željom da ostavi pečat kako u svetu umetnosti tako i u srcima ljudi."
          />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
          <meta name="robots" content="index" />
          <link rel="canonical" href="https://ananenadovic.com/sr/" />
          <meta
            property="og:title"
            content="Ana Nenadovic | Interior Design Studio"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ananenadovic.com/sr/" />
          <meta property="og:image" content={ogMetaImgSr} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="Ana Nenadovic | Interior Design Studio"
          />
          <meta
            name="twitter:description"
            content="Studio se nalazi u Beogradu. Osnovan je 2013.godine sa željom da ostavi pečat kako u svetu umetnosti tako i u srcima ljudi"
          />
          <meta name="twitter:image" content={ogMetaImgSr} />
        </Helmet>
        <CreateHeroHompageSr homepageIntroText={data.allWpPage.edges[0].thepage.homepageIntroText} />
        <VerticalProjectsSr featuredProjects={featuredProjectsElements} />
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeIn animDelay3 animWidth"
        >
          <hr className="hrSeparator" />
        </InViewMonitor>
        <HomepageWhoWeAreSr whowearehome={data.allWpPage.edges[0].thepage.homepageWhoWeAreSection} />

        <div className="stickTheFooter">
          <FooterSr />
        </div>
      </div>
    </div>
  )
}
HomepagePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomepagePage

export const pageQuery = graphql`
  query ProjectsByHomepageSR {
    allWpProjectPostType(filter: { locale: { locale: { eq: "sr_RS" } } }) {
      edges {
        project: node {
          id
          title
          slug
          content
          acfBeforeAfterImages {
            imageafterelement {
              altText
              id
              slug
              title
              caption
              mediaItemUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaDetails {
                height
                width
                file
              }
            }
            imageafterelement {
              altText
              id
              slug
              title
              caption
              mediaItemUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaDetails {
                height
                width
                file
              }
            }
          }
          featuredImage {
            node {
              id
              slug
              link
              sourceUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
            }
          }
          locale {
            locale
          }
          translations {
            locale
            id
            post_title
            href
          }
        }
      }
    }
    allWpPage(
      filter: {
        template: { templateName: { eq: "Homepage" } }
        locale: { locale: { eq: "sr_RS" } }
      }
    ) {
      edges {
        thepage: node {
          id
          slug
          status
		  title
          template {
            templateName
          }
          locale {
            locale
          }
          translations {
            locale
            id
            post_title
            href
          }
          homepageIntroText {
            homepageintro
          }
          homepageWhoWeAreSection {
            whowearesectionone
            whowearesectiontwo
          }
          homepageProjects {
            selectProjectsToShow {
              ... on WpProjectPostType {
                id
              }
            }
          }
        }
      }
    }
  }
`
